import React, { useState } from 'react';
import { useFeatureFlags } from '@stitch-fix/sf-next';
import { useAdultsIncentiveBanner } from './adults/useAdultsIncentiveBanner';
import { useKidsIncentiveBanner } from './kids/useKidsIncentiveBanner';
import { SignupIncentiveModal } from '../../components/Layout/SignupIncentiveBanner/SignupIncentiveModal';
import { useAllocation } from '../useAllocation';

// eslint-disable-next-line no-shadow
export enum ModalCampaign {
  FRONT_DOOR,
  TWENTY_DOLLAR,
  TWENTY_PERCENT,
  KIDS_FIRST_FIX,
}

// Experiment:
// https://ab.daylight.stitchfix.com/expts/1433
export const dollarVsPercentExp = {
  expectedValues: ['dollar', 'percent'],
  fallbackValue: 'dollar',
  name: 'eng.incentives.percent_vs_dollar_offer',
};

export const useIncentiveBanner = (hideHeaderFooter = false) => {
  const [showBannerPlaceholder, setShowBannerPlaceholder] = useState(
    !hideHeaderFooter,
  );
  const [showModal, setShowModal] = useState(false);

  const modalClose = () => setShowModal(false);
  const handleBannerClick = () => setShowModal(true);
  const handleBannerLoad = () => setShowBannerPlaceholder(false);

  const { featureFlags: visitorFeatureFlags, loading: ffLoading } =
    useFeatureFlags(
      'visitor',
      [
        'visitor.web.enable_adults_first_fix_discount',
        'visitor.web.enable_kids_first_fix_discount',
      ],
      {
        skip: hideHeaderFooter,
      },
    );

  const { data: allocationData, loading: expLoading } = useAllocation(
    hideHeaderFooter ? [] : [dollarVsPercentExp],
  );

  const allocation = allocationData?.visitor?.configParams?.find(
    param => param.name === dollarVsPercentExp.name,
  );

  const isAdultsFirstFixTestEnabled =
    visitorFeatureFlags?.['visitor.web.enable_adults_first_fix_discount'] ??
    false;
  const isPercentTreatment = allocation?.value === 'percent';
  const { banner: adultBanner, slimBanner: adultSlimBanner } =
    useAdultsIncentiveBanner({
      onClick: handleBannerClick,
      onLoad: handleBannerLoad,
      isAdultsFirstFixTestEnabled,
      isPercentTreatment,
      isLoading: expLoading || ffLoading,
    });

  const isKidsFirstFixEnabled =
    visitorFeatureFlags?.['visitor.web.enable_kids_first_fix_discount'] ??
    false;
  const { banner: kidsBanner, slimBanner: kidsSlimBanner } =
    useKidsIncentiveBanner({
      onClick: handleBannerClick,
      onLoad: handleBannerLoad,
      isKidsFirstFixEnabled,
      isLoading: ffLoading,
    });

  let modalCampaign;

  // We ensure adult banners only show on adult pages and kids banners only show on kids pages
  // Therefore, we can use the banner existence to inform which modal campaign to use
  if (adultBanner || adultSlimBanner) {
    if (isAdultsFirstFixTestEnabled) {
      // When the FirstFixTest is enabled, we use the modal for the respective treatment
      modalCampaign = isPercentTreatment
        ? ModalCampaign.TWENTY_PERCENT
        : ModalCampaign.TWENTY_DOLLAR;
    } else {
      // When the FirstFixTest is disabled, we default to the FrontDoor modal
      modalCampaign = ModalCampaign.FRONT_DOOR;
    }
  } else if (isKidsFirstFixEnabled && (kidsBanner || kidsSlimBanner)) {
    modalCampaign = ModalCampaign.KIDS_FIRST_FIX;
  }

  const modal = (
    <SignupIncentiveModal
      isOpen={showModal}
      close={modalClose}
      modalCampaign={modalCampaign}
    />
  );

  // We ensure adult banners only show on adult pages and kids banners only show on kids pages
  // Therefore, we can use this || logic in the banner and slimBanner return values
  return {
    showBannerPlaceholder,
    banner: adultBanner || kidsBanner,
    slimBanner: adultSlimBanner || kidsSlimBanner,
    modal,
  };
};
