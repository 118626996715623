type EventCanonicalName =
  | 'corporate'
  | 'landing'
  | 'signup'
  | 'password_reset'
  | 'login';

// Event reporter expects the payload to contain canonical_name
// this util maps a path to the expected canonical_name
const getEventCanonicalName = (path: string): EventCanonicalName => {
  const sitePageCanonicalName: Record<string, EventCanonicalName> = {
    '/about': 'corporate',
    '/careers': 'corporate',
    '/cookie-info': 'corporate',
    '/gift-card-terms': 'corporate',
    '/login': 'login',
    '/password/edit': 'password_reset',
    '/password/new': 'password_reset',
    '/privacy': 'corporate',
    '/product/signup': 'signup',
    '/signup': 'signup',
    '/sitemap': 'corporate',
    '/supply-chain-information': 'corporate',
    '/terms': 'corporate',
    '/ukeearecruitingprivacy': 'corporate',
  };

  return sitePageCanonicalName[path] ?? 'landing';
};

export default getEventCanonicalName;
