export const adultsIncentiveBannerSupportedPaths = [
  '/',
  '/d/gateway',
  '/d/men',
  '/d/women',
  '/find-your-perfect-fit',
  '/how-it-works',
  '/info/dresses',
  '/info/holiday-outfits',
  '/info/jeans',
  '/info/men-professional',
  '/info/mens-holiday',
  '/info/mens-jeans',
  '/info/mens-outerwear',
  '/info/mens-shirts',
  '/info/mens-spring',
  '/info/mens-sweaters',
  '/info/mens-winter',
  '/info/outerwear',
  '/info/plus-dresses',
  '/info/plus-jeans',
  '/info/plus-outerwear',
  '/info/plus-professional',
  '/info/plus-shirts',
  '/info/plus-sweaters',
  '/info/plus-tops',
  '/info/professional-outfits',
  '/info/shirts',
  '/info/spring-outfits',
  '/info/sweaters',
  '/info/winter-outfits',
  '/info/womens-athleisure',
  '/info/womens-date-night',
  '/info/womens-holiday',
  '/info/womens-jeans',
  '/info/womens-outerwear',
  '/info/womens-professional',
  '/info/womens-shirts',
  '/info/womens-spring-outfits',
  '/info/womens-sweaters',
  '/info/womens-winter-outfits',
  '/iphone-app',
  '/men',
  '/men/big-tall',
  '/men/mensoutfits',
  '/men/workout-clothes',
  '/our-brands',
  '/personal-stylists',
  '/pricing',
  '/stitch-fix-vs-competitors',
  '/subscription-clothing-box',
  '/t/gateway',
  '/t/men',
  '/t/women',
  '/women',
  '/women/activewear',
  '/women/allwomen',
  '/women/business-casual',
  '/women/everydaywear',
  '/women/jeans',
  '/women/katie-sturino',
  '/women/luxury-designer-brands',
  '/women/maternity',
  '/women/maternity/activewear',
  '/women/ontrendlooks',
  '/women/petite',
  '/women/plus',
  '/women/plus/activewear',
  '/women/rebecca-minkoff',
  '/women/your-first-fix',
];
