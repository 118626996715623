import { reportEvent } from '@stitch-fix/event-reporter/legacy';
import generateId from '@stitch-fix/log-weasel';
import getEventCanonicalName from './getEventCanonicalName';

interface ActionNameEvents {
  panel_view: string;
  cta_click: string;
  carousel_click: string;
  carousel_scroll: string;
  node_click: string;
  modal_dismiss: string;
  clicked_into_pdp: string;
  page_view: string;
}

interface TrackEventParams {
  actionDetail?: string;
  actionName: keyof ActionNameEvents;
  actionType: string;
  eventContext?: Record<string, unknown>;
  metricsTags?: Record<string, unknown>;
  businessLine?: string;
}

const trackEvent = ({
  actionDetail,
  actionName,
  actionType,
  eventContext,
  metricsTags,
}: TrackEventParams) => {
  const requestId = generateId('growth-next-ui');

  const path = window.location.pathname;

  // using the vx.* prefix to match old event name
  const actionNameToEventMapping: ActionNameEvents = {
    panel_view: 'vx.panel-view',
    clicked_into_pdp: 'vx.clicked_into_pdp',
    cta_click: 'vx.cta-click',
    carousel_click: 'vx.carousel-click',
    carousel_scroll: 'vx.carousel-scroll',
    node_click: 'vx.node-click',
    modal_dismiss: 'vx.modal-click',
    page_view: 'vx.page-view',
  };

  reportEvent({
    event: {
      canonical_name: getEventCanonicalName(path),
      source_app: 'growth-next-ui',
      action_type: actionType,
      action_name: actionName,
      event_name: actionNameToEventMapping[actionName],
      action_detail: actionDetail,
      event_context: eventContext,
      metrics_tags: metricsTags || {},
    },
    requestId,
  });
};

export const trackPanelView = ({
  actionDetail,
  eventContext,
}: Pick<TrackEventParams, 'actionDetail' | 'eventContext'>) => {
  trackEvent({
    actionType: 'view',
    actionName: 'panel_view',
    actionDetail,
    eventContext,
  });
};

export const trackPDPClick = ({
  eventContext,
}: Pick<TrackEventParams, 'eventContext'>) => {
  trackEvent({
    actionType: 'click',
    actionName: 'clicked_into_pdp',
    actionDetail: 'pdp_click',
    eventContext,
  });
};

export const trackCtaClick = ({
  actionDetail,
  eventContext,
}: Pick<TrackEventParams, 'actionDetail' | 'eventContext'>) => {
  trackEvent({
    actionType: 'click',
    actionName: 'cta_click',
    actionDetail,
    eventContext,
  });
};

export const trackCarouselPanelClick = ({
  eventContext,
}: Pick<TrackEventParams, 'eventContext'>) => {
  trackEvent({
    actionType: 'click',
    actionName: 'carousel_click',
    actionDetail: 'panel_change',
    eventContext,
  });
};

export const trackCarouselPanelScroll = ({
  eventContext,
}: Pick<TrackEventParams, 'eventContext'>) => {
  trackEvent({
    actionType: 'scroll',
    actionName: 'carousel_scroll',
    actionDetail: 'panel_change',
    eventContext,
  });
};

export const trackNodeClick = ({
  eventContext,
}: Pick<TrackEventParams, 'eventContext'>) => {
  trackEvent({
    actionType: 'click',
    actionName: 'node_click',
    actionDetail: 'image_view',
    eventContext,
  });
};

export const trackModalDismiss = ({
  actionDetail,
}: Pick<TrackEventParams, 'actionDetail'>) => {
  trackEvent({
    actionType: 'click',
    actionName: 'modal_dismiss',
    actionDetail,
  });
};

export const trackPageView = () => {
  trackEvent({
    actionType: 'view',
    actionName: 'page_view',
  });
};
