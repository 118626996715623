/* THIS IS FILE IS AUTO-GENERATED BY graphql-codegen. DO NOT EDIT THIS FILE DIRECTLY! */
/* eslint-disable @typescript-eslint/no-unused-vars, no-restricted-syntax */

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as Types from '../../types/definitions/vendor/graphql-schema.d';

const defaultOptions = {} as const;

export type GetConfigParamsQueryVariables = Types.Exact<{
  input: Types.ConfigParamsInput;
  visitorId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;

export type GetConfigParamsQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    configParams: {
      __typename?: 'ConfigParam';
      name: string;
      value: string;
    }[];
  } | null;
  visitor: {
    __typename?: 'Visitor';
    id: string;
    configParams: {
      __typename?: 'ConfigParam';
      name: string;
      value: string;
    }[];
  };
};

export const GetConfigParamsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetConfigParams' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConfigParamsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visitorId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'client' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configParams' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'visitor' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'visitorId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'configParams' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetConfigParamsQuery__
 *
 * To run a query within a React component, call `useGetConfigParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigParamsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      visitorId: // value for 'visitorId'
 *   },
 * });
 */
export function useGetConfigParamsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConfigParamsQuery,
    GetConfigParamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetConfigParamsQuery, GetConfigParamsQueryVariables>(
    GetConfigParamsDocument,
    options,
  );
}
export function useGetConfigParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConfigParamsQuery,
    GetConfigParamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetConfigParamsQuery,
    GetConfigParamsQueryVariables
  >(GetConfigParamsDocument, options);
}
export type GetConfigParamsQueryHookResult = ReturnType<
  typeof useGetConfigParamsQuery
>;
export type GetConfigParamsLazyQueryHookResult = ReturnType<
  typeof useGetConfigParamsLazyQuery
>;
export type GetConfigParamsQueryResult = Apollo.QueryResult<
  GetConfigParamsQuery,
  GetConfigParamsQueryVariables
>;
