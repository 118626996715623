import { useEffect, useMemo, useRef, useState } from 'react';
import type { HeaderProps } from '@stitch-fix/knit';
import { useRouter } from 'next/router';
import { IconFixDelivery16 } from '@stitch-fix/mode-react';
import { useLocalStorageValue } from '@react-hookz/web';
import { kidsIncentiveBannerSupportedPaths } from './kidsIncentiveBannerSupportedPaths';
import {
  type BannerLocalStorageData,
  type IncentiveBannerTypes,
  knitBannersLocalStorageKey,
  type UseIncentiveBannerProps,
} from '../types';

export const KidsFirstFixDiscountCopy = {
  id: 'KidsFirstFixDiscountBanner-20percent',
  text: 'Kids first Fix offer! 20% off + waived styling fee',
  Icon: IconFixDelivery16,
};

export const useKidsIncentiveBanner = ({
  onClick,
  onLoad,
  isKidsFirstFixEnabled,
  isLoading,
}: UseIncentiveBannerProps): IncentiveBannerTypes => {
  const { asPath } = useRouter();
  const hasCalledOnLoad = useRef(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [closedBanners] = useLocalStorageValue<
    BannerLocalStorageData | undefined
  >(
    knitBannersLocalStorageKey,
    {},
    {
      initializeWithStorageValue: false,
    },
  );

  const { id, text, Icon } = KidsFirstFixDiscountCopy;

  const hasBannerBeenClosed = closedBanners?.[id]?.closed;

  const normalizedPath = asPath.split('?')[0];

  const shouldIncludeBanner = useMemo(() => {
    return kidsIncentiveBannerSupportedPaths.includes(normalizedPath);
  }, [normalizedPath]);

  const isBannerShown =
    shouldIncludeBanner && !hasBannerBeenClosed && hasLoaded;

  useEffect(() => {
    // We don't know which one to set until the Feature Flags are loaded
    // Don't execute the cookie logic until we know which one to set
    if (isLoading) return;

    if (!hasCalledOnLoad.current) {
      hasCalledOnLoad.current = true;
      setHasLoaded(true);
      onLoad?.();
    }
  }, [
    onLoad,
    shouldIncludeBanner,
    isBannerShown,
    isKidsFirstFixEnabled,
    isLoading,
  ]);

  if (!isKidsFirstFixEnabled || !isBannerShown || isLoading) return {};

  const slimBanner: HeaderProps['slimBanner'] = {
    text,
    id,
    cta: {
      text: 'See details',
      onClick,
    },
    variant: 'promo-dark',
    icon: <Icon purpose="decorative" color="inherit" />,
  };

  return { slimBanner };
};
