import { recordMark } from '@stitch-fix/client-analytics-reporter';
import { VISITOR_COOKIE_NAME } from '@stitch-fix/client-facing-auth/browser';
import cookies from 'browser-cookies';
import type {
  ConfigParamInput,
  ConfigParamsInput,
} from '../../types/definitions/vendor/graphql-schema.d';

import {
  useGetConfigParamsLazyQuery,
  useGetConfigParamsQuery,
} from './getConfigParams';

const isBrowser = () => typeof window !== 'undefined';

export const getVisitorIdCookie = () => {
  if (!isBrowser()) {
    return '';
  }

  return cookies.get(VISITOR_COOKIE_NAME) || '';
};

type UseAllocationOptions = {
  offlineLookupOnly?: ConfigParamsInput['offlineLookupOnly'];
};

export const useAllocation = (
  params: ConfigParamInput[],
  { offlineLookupOnly = false }: UseAllocationOptions = {},
) =>
  useGetConfigParamsQuery({
    skip: !params?.length,
    variables: {
      input: {
        offlineLookupOnly,
        exposureDetails: {
          referrer: isBrowser() ? window.document.referrer : '',
          uri: isBrowser() ? window.location.href : '',
        },
        params,
      },
      visitorId: getVisitorIdCookie(),
    },
    onCompleted: () => {
      recordMark('get_config_params_graph_query_completed', {
        graph_query_succeeded: true,
      });
    },
    onError: () => {
      recordMark('get_config_params_graph_query_completed', {
        graph_query_succeeded: false,
      });
    },
  });

export const useLazyAllocation = (params: ConfigParamInput[]) =>
  useGetConfigParamsLazyQuery({
    variables: {
      input: {
        offlineLookupOnly: false,
        exposureDetails: {
          referrer: isBrowser() ? window.document.referrer : '',
          uri: isBrowser() ? window.location.href : '',
        },
        params,
      },
      visitorId: getVisitorIdCookie(),
    },
    onCompleted: () => {
      recordMark('get_config_params_graph_query_completed', {
        graph_query_succeeded: true,
      });
    },
    onError: () => {
      recordMark('get_config_params_graph_query_completed', {
        graph_query_succeeded: false,
      });
    },
  });
