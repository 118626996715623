import { useEffect, useMemo, useRef, useState } from 'react';
import type { HeaderProps } from '@stitch-fix/knit';
import { useRouter } from 'next/router';
import { IconFixDelivery16, IconSuccess16 } from '@stitch-fix/mode-react';
import { useLocalStorageValue } from '@react-hookz/web';
import { adultsIncentiveBannerSupportedPaths } from './adultsIncentiveBannerSupportedPaths';
import {
  type BannerLocalStorageData,
  type IncentiveBannerTypes,
  knitBannersLocalStorageKey,
  type UseIncentiveBannerProps,
} from '../types';

export const FrontDoorCreditCopy = {
  id: 'FrontdoorSignupCreditBanner-20usd',
  text: "Get $20 off + we'll waive the styling fee on your first Fix",
  Icon: IconSuccess16,
};
export const TwentyDollarIncentiveCopy = {
  id: 'AdultsFirstFixDiscountBanner-20dollar',
  text: 'First Fix offer! $20 off + waived styling fee',
  Icon: IconFixDelivery16,
};
export const TwentyPercentIncentiveCopy = {
  id: 'AdultsFirstFixDiscountBanner-20percent',
  text: 'First Fix offer! 20% off + waived styling fee',
  Icon: IconFixDelivery16,
};

export const useAdultsIncentiveBanner = ({
  onClick,
  onLoad,
  isAdultsFirstFixTestEnabled,
  isPercentTreatment,
  isLoading,
}: UseIncentiveBannerProps): IncentiveBannerTypes => {
  const { asPath } = useRouter();
  const hasCalledOnLoad = useRef(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [closedBanners] = useLocalStorageValue<
    BannerLocalStorageData | undefined
  >(
    knitBannersLocalStorageKey,
    {},
    {
      initializeWithStorageValue: false,
    },
  );

  let copy = FrontDoorCreditCopy;

  if (isAdultsFirstFixTestEnabled) {
    copy = isPercentTreatment
      ? TwentyPercentIncentiveCopy
      : TwentyDollarIncentiveCopy;
  }

  const { id, text, Icon } = copy;

  const hasBannerBeenClosed = closedBanners?.[id]?.closed;

  const normalizedPath = asPath.split('?')[0];

  const shouldIncludeBanner = useMemo(() => {
    return adultsIncentiveBannerSupportedPaths.includes(normalizedPath);
  }, [normalizedPath]);

  const isBannerShown =
    shouldIncludeBanner && !hasBannerBeenClosed && hasLoaded;

  useEffect(() => {
    // We don't know which one to set until the Experiment is loaded
    if (isLoading) return;

    if (!hasCalledOnLoad.current) {
      hasCalledOnLoad.current = true;
      setHasLoaded(true);
      onLoad?.();
    }
  }, [isLoading, onLoad]);

  if (!isBannerShown || isLoading) return {};

  const slimBanner: HeaderProps['slimBanner'] = {
    text,
    id,
    cta: {
      text: 'See details',
      onClick,
    },
    variant: 'promo-dark',
    icon: <Icon purpose="decorative" color="inherit" />,
  };

  return { slimBanner };
};
