import { clientAuthAxios } from './clientAuth';

export type CreateKidResponse =
  | CreateKidSuccessResponse
  | CreateKidErrorResponse;

export type CreateKidErrorResponse = {
  errors: { code: string }[];
};

export type CreateKidSuccessResponse = {
  success: {
    business_line: string;
    external_id: string;
    kid_id: number;
    kid_external_id: string;
    access_token: string;
    refresh_token: string;
  };
};
/**
 * A function that posts to the `/client-auth-api/api/kid_accounts` endpoint.
 *
 * - [Documentation](https://unwritten.stitchfix.com/apidocs/web/client-auth-api/kid_accounts/post_returns_new_account_details.html)
 * - [Implementation](https://github.com/stitchfix/client-auth-api/blob/main/app/controllers/api/v1/kid_accounts_controller.rb)
 *
 */

export const createKidAccount = () =>
  clientAuthAxios.post<CreateKidResponse>('kid_accounts', {
    login_as_new_client: true,
    scope: 'default',
  });

// ==========
// Test Utils
// ==========

type CreateKidHandler = { status: number } & (
  | CreateKidErrorResponse
  | CreateKidSuccessResponse
);

export const mockCreateKidLimitExceededError: CreateKidHandler = {
  errors: [{ code: 'limit_exceeded' }],
  status: 422,
};

export const mockCreateKidAccountDeactivatedError: CreateKidHandler = {
  errors: [{ code: 'deactivated_client' }],
  status: 422,
};

export const mockCreateKidAccountUnknownError: CreateKidHandler = {
  errors: [{ code: 'unknown' }],
  status: 500,
};

export const mockCreateKidAccountSuccess: CreateKidHandler = {
  success: {
    business_line: 'Kids',
    external_id: '122externalid',
    kid_id: 123,
    kid_external_id: '123externalid',
    access_token: 'supersecrettoken',
    refresh_token: 'refreshtoken',
  },
  status: 200,
};
