import type { HeaderProps } from '@stitch-fix/knit';

export interface UseIncentiveBannerProps {
  onClick: () => void;
  onLoad?: () => void;
  isKidsFirstFixEnabled?: boolean;
  isAdultsFirstFixTestEnabled?: boolean;
  isPercentTreatment?: boolean;
  isLoading?: boolean;
}

export interface IncentiveBannerTypes {
  slimBanner?: HeaderProps['slimBanner'];
  banner?: HeaderProps['banner'];
}

/**
 * Knit uses LocalStorage to store which banners have been previously been closed
 * and prevent the banner from rendering.
 * We are relying on Knit's usage of LocalStorage
 * to call `onLoad` as soon as possible to hide the banner placeholder to reduce CLS.
 *
 * IMPORTANT: This is workaround is brittle since we are assuming Knit's implementation will not change.
 * TODO: Figure out a more sustainable solution that better integrates with Knit's banner system.
 * Source: https://github.com/stitchfix/knit/blob/b14ac85479c4fb3c0be508830398235cae04decf/src/components/Header/Banners/index.tsx
 */
export type BannerLocalStorageData = Record<string, { closed: boolean }>;
export const knitBannersLocalStorageKey = 'Knit:Banners';
