import axios from 'axios';
import generateId from '@stitch-fix/log-weasel';
import packageInfo from '../../../../package.json';

const appName = packageInfo.name;

/**
 * Setting [custom instance defaults] https://axios-http.com/docs/config_defaults
 *
 * maybe we'll need to break this out in to global defaults and different services,
 * but this should suffice for now
 *
 */
export const clientAuthAxios = axios.create({
  baseURL: 'https://www.stitchfix.com/client-auth-api/api/',
  withCredentials: true,
  headers: {
    Accept: 'application/json; version=1',
    'Content-Type': 'application/json',
    /**
     * allows us to bypass CSRF
     * https://github.com/stitchfix/client-auth-api/pull/387/files#diff-a402442719412a456e9523a1be22ef9413a5161f29c176dfe2701e2413d3ba0cR7
     */
    'X-SF-NEXT-APP': appName,
    'X-Request-Id': generateId(appName),
  },
});

export const clientAuthV2Axios = axios.create({
  baseURL: 'https://www.stitchfix.com/client-auth-api/api/',
  withCredentials: true,
  headers: {
    Accept: 'application/json; version=2',
    'Content-Type': 'application/json',
    /**
     * allows us to bypass CSRF
     * https://github.com/stitchfix/client-auth-api/pull/387/files#diff-a402442719412a456e9523a1be22ef9413a5161f29c176dfe2701e2413d3ba0cR7
     */
    'X-SF-NEXT-APP': appName,
    'X-Request-Id': generateId(appName),
  },
});
