import {
  Button,
  Box,
  IconError24,
  Text,
  Link,
  type ButtonProps,
  type TextProps,
} from '@stitch-fix/mode-react';
import { useMachine } from '@xstate/react';
import { loadTranslations, Trans } from '../../i18n';
import translations from './AddAKidButton-translations.json';
import { addAKidMachine } from '../../machines/add-a-kid/add-a-kid';
import styles from './styles.module.scss';

const { useTranslation } = loadTranslations(translations, 'addAKidButton');

type AddAKidButtonProps = {
  errorTextColor?: TextProps<'p'>['color'];
} & ButtonProps<'button'>;

export const AddAKidButton = ({
  errorTextColor,
  ...rest
}: AddAKidButtonProps) => {
  const { t } = useTranslation();
  const [current, send] = useMachine(addAKidMachine);

  const addAKid = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    send('ADD_A_KID_REQUEST');
  };

  return (
    <>
      <Button
        as="button"
        onClick={addAKid}
        disabled={current.matches('loading')}
        {...rest}
      >
        {current.matches('loading') ? t('loading') : t('label')}
      </Button>
      {current.matches('failure') && (
        <Box mt={0.5} className={styles.error}>
          <IconError24 title="Error" color={errorTextColor} />
          <Text as="p" setting="body-small" color={errorTextColor}>
            <Trans
              t={t}
              i18nKey={
                current.matches('failure.limitExceeded')
                  ? 'limitExceededError'
                  : 'unknownError'
              }
              components={{
                ContactLink: (
                  // @ts-expect-error children is provided by i18n translations
                  <Link
                    href="https://support.stitchfix.com/hc/en-us/requests/new"
                    variant="inherit"
                  />
                ),
              }}
            />
          </Text>
        </Box>
      )}
    </>
  );
};
