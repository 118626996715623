import React from 'react';
import { Box, Button, Modal, Text } from '@stitch-fix/mode-react';
import { useClientAuth } from '@stitch-fix/sf-next';
import { AddAKidButton } from '../../AddAKidButton';
import { CloudinaryImageWrapper } from '../../CloudinaryImageWrapper';
import { ModalCampaign } from '../../../hooks/useIncentiveBanner/useIncentiveBanner';

import styles from './style.module.scss';

const modalVariant = () => {
  if (window?.location?.pathname?.match('/women')) return 'women';
  if (window?.location?.pathname?.match('/men')) return 'men';

  return 'default';
};

interface ModalProps {
  isOpen: boolean;
  close: () => void;
  modalCampaign?: ModalCampaign;
}

export const FrontDoorCreditCopy = {
  heading: 'Get $20 off + we’ll waive the styling fee on your first Fix',
  body: 'Get $20 off your first Fix and we’ll waive the $20 styling fee. Fill out your Style Profile and schedule your Fix to get started.',
  subText: '',
  cta: 'GET MY CREDIT',
};

export const TwentyDollarIncentiveCopy = {
  heading: 'First Fix offer! $20 off + waived styling fee',
  body: 'Take your style quiz and schedule your Fix.',
  subText: 'Offer expires 7 days after signing up.',
  cta: 'Sign up now',
};

export const TwentyPercentIncentiveCopy = {
  heading: 'First Fix offer! 20% off + waived styling fee',
  body: 'Take your style quiz and schedule your Fix.',
  subText: 'Offer expires 7 days after signing up.',
  cta: 'Sign up now',
};

export const KidsFirstFixDiscountCopy = {
  heading: 'Kids first Fix offer! 20% off + waived styling fee',
  body: 'Take their style quiz and schedule their Fix.',
  subText: '',
  cta: 'Sign up now',
};

const FD_IMAGE_VARIANTS = {
  men: {
    path: 'landing-pages/pages/US/BRND-FY24-WEB-Rebrand%20Client%20Experience%20Audit-1207669956273077/Modal%20Promo/Modal_Promo_M.png',
    alt: 'Stitch Fix men’s spring clothing including yellow pullover, rust and aqua colored shorts, folded khakis, and folded button down shirts.',
    width: 3342,
    height: 3342,
  },
  women: {
    path: 'landing-pages/pages/US/BRND-FY24-WEB-Rebrand%20Client%20Experience%20Audit-1207669956273077/Modal%20Promo/Modal_Promo_W.png',
    alt: 'Stitch Fix women’s spring clothing including multicolored maxi dress, pink skirt, v-neck black and white sweater and checkered skirt.',
    width: 3964,
    height: 3964,
  },
  default: {
    path: 'landing-pages/pages/US/BRND-FY24-WEB-Rebrand%20Client%20Experience%20Audit-1207669956273077/Modal%20Promo/Modal_Promo_GN.png',
    alt: 'Stitch Fix spring men’s and women’s clothing including blue jeans, sage colored dress, and button down short sleeve shirt.',
    width: 2000,
    height: 2000,
  },
};

const KIDS_FF_IMAGE = {
  path: 'landing-pages/pages/US/gateway/Kids_First_Fix_Offer.png',
  alt: 'A boy and a girl holding stacks of Stitch Fix boxes.',
  width: 3000,
  height: 2401,
};

export const SignupIncentiveModal = ({
  isOpen,
  close,
  modalCampaign,
}: ModalProps) => {
  const { authStatus } = useClientAuth();
  const loggedIn = authStatus === 'authenticated';

  if (modalCampaign == null) return null;

  let content = FrontDoorCreditCopy;
  // This is safe because this modal can only be rendered on the client
  let image = FD_IMAGE_VARIANTS[modalVariant()];

  if (modalCampaign === ModalCampaign.TWENTY_DOLLAR) {
    content = TwentyDollarIncentiveCopy;
  } else if (modalCampaign === ModalCampaign.TWENTY_PERCENT) {
    content = TwentyPercentIncentiveCopy;
  } else if (modalCampaign === ModalCampaign.KIDS_FIRST_FIX) {
    content = KidsFirstFixDiscountCopy;
    image = KIDS_FF_IMAGE;
  }

  const { heading, body, subText, cta } = content;

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      heading={heading}
      hero={
        <div className={styles.image}>
          <CloudinaryImageWrapper
            src={`https://res.cloudinary.com/stitch-fix/image/upload/${image.path}`}
            alt={image.alt}
            lazy
            objectFit="cover"
            sources={{
              sm: {
                w: 560,
                h: 560,
              },
            }}
            cloudinaryAsset={{
              path: image.path,
              width: image.width,
              height: image.height,
            }}
            maxContainerWidth={560}
            // height: undefined is needed to override the inline style that sets the height to auto
            imageProps={{ loading: 'lazy', style: { height: undefined } }}
          />
        </div>
      }
      variant="inset-small"
      hAlign="center"
      bodyLock="never"
    >
      <Text setting="body-large">{body}</Text>
      {subText && <Text setting="body-medium">{subText}</Text>}
      <Box maxWidth={18} mx="auto" mt={1}>
        {loggedIn && modalCampaign === ModalCampaign.KIDS_FIRST_FIX ? (
          <AddAKidButton variant="filled-staple" />
        ) : (
          <Button
            as="a"
            href="/signup"
            variant="filled-staple"
            data-testid="modal-cta"
            onClick={close}
          >
            {cta}
          </Button>
        )}
      </Box>
    </Modal>
  );
};
