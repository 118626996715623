import Script from 'next/script';

// https://validator.schema.org/
// https://search.google.com/test/rich-results
const data = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Stitch Fix',
  url: 'https://www.stitchfix.com/',
  logo: `${process.env.NEXT_PUBLIC_CLOUDFRONT_PATH}/image/upload/v1680644457/landing-pages/logo.png`,
  sameAs: [
    'https://www.facebook.com/stitchfix',
    'https://www.instagram.com/stitchfix/',
    'https://en.wikipedia.org/wiki/Stitch_Fix',
    'https://twitter.com/stitchfix',
    'https://www.pinterest.com/stitchfix/',
  ],
};

// JSON-LD (JavaScript Object Notation for Linked Data) is used to improve SEO (Search Engine Optimization)
// by informing search engines how to attribute website data with the content
// and brand to display information in a consistent and structured way.
// https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data
export const JsonLd = () => {
  return (
    <Script
      id="app-ld-json"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(data),
      }}
    />
  );
};
